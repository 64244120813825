import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { T } from '../clever-ui-kit/typo'
import { globals, s, colors, alpha, sHtml } from '../clever-ui-kit/style'
// import '../clever-ui-kit/style/unused/styleOLD.css'

// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { IntroBlog } from '../clever-ui-kit/intros'

export default function BlogTemplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState.language
  const theme = state?.theme ? state.theme : initialState.theme
  const author = data?.author?.frontmatter
  const blog = data.thisBlog?.frontmatter
  const blogHtml = data.thisBlog?.html

  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    if (blog.language)
      dispatch({ type: 'SET_LANGUAGE', payload: blog.language })
  }, [])
  /*eslint-enable */

  return (
    <>
      <SEO
        title={blog.seo_title || blog.title}
        description={blog.seo_desc || blog.description}
        image={blog.image?.publicURL}
      />
      <IntroBlog
        title={blog.title}
        subtitle={blog.description}
        image={blog.image}
        author={author}
        date={blog.date}
        theme={theme}
        language={blog.language}
      />
      <div css={sTitle}>
        <T
          d={64}
          t={48}
          m={32}
          mb={0.5}
          bold
          variant="h1"
          extraCss={sTitle.headline}>
          {blog.title}
        </T>
        <T d={20} t={18} m={14} o={1} normal>
          {blog.description}
        </T>
      </div>
      <div
        css={[sHtml, sBlog, sTheme(theme)]}
        dangerouslySetInnerHTML={{ __html: blogHtml }}
      />
    </>
  )
}

const sTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[80]),
})

const sBlog = {
  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
    borderRadius: globals.roundness.sections.mobile,
    marginBottom: globals.spacing.inside.mobile,
  },
  [s.md]: {
    boxShadow: globals.shadows.sections.desktop,
    borderRadius: globals.roundness.sections.desktop,
    marginBottom: globals.spacing.inside.desktop,
  },
}

const sTitle = {
  margin: '0 auto',
  width: '100%',
  maxWidth: globals.maxWidth,
  [s.lg_1280]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  marginTop: '6rem',
  marginBottom: '6rem',
  textAlign: 'center',
  [s.sm_down]: {
    padding: '0 2rem',
    marginBottom: '4rem',
  },
  headline: {
    letterSpacing: '-0.05em',
  },
}

export const query = graphql`
  query($title: String!, $author: String) {
    thisBlog: markdownRemark(
      fileAbsolutePath: { regex: "/blog/" }
      frontmatter: { title: { eq: $title } }
    ) {
      html
      frontmatter {
        title
        name
        url
        language
        author
        description
        date
        seo_title
        seo_desc
        image {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    author: markdownRemark(
      fileAbsolutePath: { regex: "/authors/" }
      frontmatter: { title: { eq: $author } }
    ) {
      frontmatter {
        title
        subtitle
        description
        image {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # sections: allMarkdownRemark(
    #   filter: {
    #     fileAbsolutePath: { regex: "/sections/" }
    #     frontmatter: { name: { in: $sections }, language: { eq: $language } }
    #   }
    # ) {
    #   nodes {

    #   }
    # }
    # otherComponents:
  }
`
